@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
  .clippy {

    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 6vw));

  }

}

.fill-opacity-50 {
  fill-opacity: 0.5;
}



.custom-position {
  object-position: 55% 0px;
}



.gradient {
  z-index: 0;
  background-color: #1A1540;

  background: radial-gradient(ellipse at top, rgb(22 18 55 / -40%), rgba(30, 0, 255, 0.3)), url(https://grainy-gradients.vercel.app/noise.svg); 

}


/* .custom-height{
  height:50vh
} */


/* @media (max-height: 1074px) {
  .custom-image-height {
    height: 30rem;
    width:auto;
    
  }
} */

.blinking-cursor {
  color:  #4f46e5;
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

/* Add these at the top of your index.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;
  min-height: 100vh;
}

#root {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Add this if you don't already have it */
.test-gradient {
  z-index: 0;
  background-color: #1A1540;
  background: radial-gradient(ellipse at top, rgb(22 18 55 / -40%), rgba(30, 0, 255, 0.3)), url(https://grainy-gradients.vercel.app/noise.svg);
}
